import React from 'react';
import Print from './Print';

const BottomBlocRight = () => {
    return (
        <div id="bottom-bloc-right">
            <Print/>
        </div>
    );
};

export default BottomBlocRight;