export const svgTelescope = <svg className="telescope" version="1.0" xmlns="http://www.w3.org/2000/svg"
width="586.000000pt" height="478.000000pt" viewBox="0 0 586.000000 478.000000"
preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,478.000000) scale(0.100000,-0.100000)"
fill="currentColor" stroke="none">
<path d="M4171 4415 c-282 -118 -520 -220 -528 -226 -25 -21 -45 -71 -45 -108
1 -24 129 -346 371 -930 204 -491 377 -904 386 -917 21 -32 71 -56 114 -56 45
1 1064 422 1098 454 30 28 43 76 33 124 -4 22 -172 437 -374 924 -412 995
-389 951 -488 949 -45 -1 -129 -32 -567 -214z"/>
<path d="M3155 3735 c-1091 -531 -2392 -1169 -2412 -1182 -28 -19 -63 -82 -63
-115 0 -13 19 -69 42 -125 23 -57 40 -104 37 -106 -2 -2 -122 -52 -266 -112
-284 -117 -314 -131 -338 -169 -33 -49 -22 -100 65 -312 95 -230 118 -274 156
-294 54 -30 97 -18 377 99 148 61 273 113 279 114 6 1 28 -41 50 -93 22 -52
47 -103 56 -114 31 -33 73 -49 121 -43 25 2 289 88 586 190 296 102 540 185
542 184 1 -1 -90 -277 -202 -612 -119 -354 -204 -626 -205 -648 0 -50 23 -93
63 -117 27 -17 51 -20 150 -20 l117 0 36 33 c33 30 45 62 185 482 82 248 165
495 184 550 l35 100 122 -1 122 0 183 -550 c172 -517 185 -551 219 -582 l35
-34 130 4 131 3 34 37 c58 63 58 61 -169 743 l-204 610 38 59 c50 76 75 143
90 244 l12 81 146 51 c80 27 206 71 279 96 72 26 132 51 132 56 0 10 -645
1571 -658 1594 -5 7 -80 -25 -237 -101z m-187 -1524 c73 -37 107 -105 100
-197 -15 -184 -258 -245 -365 -91 -24 35 -28 50 -28 112 0 63 3 77 30 115 59
85 167 110 263 61z"/>
</g>
</svg>
