import React from 'react';

const AnimBgProfil = () => {
    return (
        <div className="circle">
            <div className="circle2">
                <div className="little-round"></div>
            </div>
        </div>
    );
};

export default AnimBgProfil;