export const svgIconInfoNotPoint = (
  <svg
    className="icon-info-not-point"
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="4500.000000pt"
    height="6794.000000pt"
    viewBox="0 0 4500.000000 6794.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,6794.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M5051 64399 c-308 -33 -641 -147 -908 -312 -563 -350 -935 -949
-1003 -1616 -14 -145 -14 -55857 0 -56002 104 -1024 905 -1825 1929 -1929 145
-14 34727 -14 34872 0 1024 104 1825 905 1929 1929 7 71 10 7089 10 22767 l0
22664 -500 0 -500 0 0 -22662 c0 -14493 -4 -22695 -10 -22753 -12 -116 -64
-250 -160 -417 -61 -104 -254 -299 -359 -361 -166 -99 -307 -154 -426 -167
-123 -13 -34717 -13 -34840 0 -116 12 -252 65 -417 161 -105 61 -299 253 -361
358 -99 166 -154 307 -167 426 -13 124 -13 55846 0 55972 8 77 19 113 63 215
85 198 134 272 272 407 124 122 193 169 343 238 132 60 174 73 267 83 57 6
4225 10 11463 10 l11372 0 0 500 0 500 -11392 -1 c-6501 -1 -11429 -5 -11477
-10z"
      />
      <path
        d="M30540 60302 c0 -4056 0 -4110 20 -4253 34 -244 87 -422 191 -638
297 -620 888 -1046 1572 -1136 105 -13 648 -15 4838 -15 l4719 0 -2 479 -3
478 -5185 4583 c-2852 2521 -5186 4589 -5188 4597 -3 11 -89 13 -483 13 l-479
0 0 -4108z m5395 -1167 c2420 -2138 4409 -3896 4420 -3906 19 -19 -61 -19
-3990 -17 l-4010 3 -60 22 c-33 13 -125 55 -205 95 -142 71 -147 75 -265 193
-144 143 -193 221 -293 465 l-27 65 -3 3499 c-2 3341 -1 3499 15 3484 10 -8
1998 -1765 4418 -3903z"
      />
      <path
        d="M26780 34473 c-63 -3 -484 -24 -1070 -53 -515 -25 -1534 -75 -1930
-95 -168 -9 -440 -22 -605 -30 -165 -8 -347 -17 -405 -20 -313 -16 -721 -36
-1000 -50 -173 -8 -403 -20 -510 -25 -107 -6 -289 -15 -405 -20 -115 -5 -298
-14 -405 -20 -107 -6 -514 -26 -905 -45 -845 -41 -867 -42 -1110 -55 -104 -5
-379 -19 -610 -30 -231 -11 -505 -25 -610 -30 -199 -11 -593 -30 -1105 -55
-630 -30 -918 -46 -1212 -65 -167 -10 -382 -24 -478 -30 -601 -36 -1027 -102
-1340 -206 -41 -14 -125 -50 -187 -80 -87 -43 -129 -71 -187 -125 -93 -87
-127 -152 -134 -255 -22 -333 385 -559 1218 -678 170 -24 366 -43 905 -86
1572 -127 2516 -388 2775 -770 294 -432 512 -1570 635 -3305 8 -115 17 -237
20 -270 11 -123 52 -867 80 -1445 30 -616 52 -1162 70 -1700 14 -417 20 -632
45 -1565 5 -203 12 -599 15 -880 3 -280 8 -563 10 -627 13 -331 17 -2379 6
-2913 -22 -1087 -67 -1928 -131 -2430 -60 -473 -109 -746 -177 -990 -64 -231
-145 -368 -314 -530 -471 -452 -1465 -722 -2979 -810 -1304 -76 -2034 -298
-2160 -659 -40 -114 -16 -273 58 -385 177 -267 732 -416 1552 -416 140 0 332
6 425 13 410 31 739 60 1165 102 284 29 1008 78 1520 105 1259 65 2270 82
4030 66 2363 -22 4329 -76 6650 -186 650 -30 1018 -45 2205 -87 457 -17 1087
-17 1280 0 320 27 525 63 708 123 238 78 391 189 464 337 37 74 38 79 38 186
0 103 -2 115 -29 165 -170 324 -836 529 -2066 636 -279 24 -738 85 -995 131
-727 131 -1286 323 -1670 575 -137 89 -321 271 -413 406 -191 281 -315 650
-372 1098 -34 273 -41 507 -30 989 24 1067 43 1952 70 3251 5 270 15 684 22
920 25 840 4 6574 -32 8725 -5 311 -12 732 -15 935 -6 417 -20 1043 -40 1810
-5 189 -10 331 -35 1045 -6 162 -13 321 -16 353 l-7 57 -111 -2 c-61 -1 -136
-3 -166 -5z"
      />
    </g>
  </svg>
);
